import axios from 'axios'

const DealerStore = {
  namespaced: true,
  state: {
    dealers: [],
    mainContent: '',
    center: { lat: -25.274398, lng: 133.77513599999997 },
    zoom : 5,
    selected : null,
    state : '',
    postcode : '',
    states: ["ACT", "NSW", "NT", "SA", "TAS", "QLD", "VIC", "WA", "NZ", "PNG"],
    kiosk_states: ["ACT", "NSW", "NT", "SA", "TAS", "QLD", "VIC", "WA"],
    source: 'paccar'
  },
  mutations: {
    many(state, data) {
      let current_states = state.source == 'paccar' ? state.states : state.kiosk_states
      if (state.source != 'paccar') {
        state.dealers = data.dealers.filter(dealer => current_states.includes(dealer.state.toUpperCase()))
      } else {
        state.dealers = data.dealers
      } 
      
      return state
    },
    set_source(state, source) {
      state.source = !source || source == '' ? 'paccar' : source
      return state
    }
  },
  actions: {
    index(context, query) {
      context.commit('set_source', 'paccar')
      axios
        .get('/api/dealers')
        .then(response => context.commit('many', response.data))
    },
    kiosk_index(context, query) {
      context.commit('set_source', 'ext')
      axios
        .get('/api/dealers')
        .then(response => context.commit('many', response.data))
    }
  }
}

export default DealerStore
