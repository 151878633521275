<template>
  <div v-html="mainContent"></div>
</template>
<script>
  import Vue from 'vue/dist/vue.runtime'
  import axios from 'axios'
  import afterBindScript from '../../mixins/after_bind_script'

  export default {
    mixins: [afterBindScript],
    data() {
      return this.$store.state.FrontEndStore
    },
    mounted() {
      let slug = this.$route.fullPath
      if(slug == '/') { slug = '/root' }

      axios
        .get('/api' + slug)
        .then(response => {
          this.mainContent = response.data.content
          this.runScript()
        })
    },
    methods: {
    }
  }
</script>
