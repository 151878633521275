<template>
  <div v-once>
    <div id="hubspotForm"></div>
  </div>
</template>

<script>
  export default {
    props: ['portalId', 'formId'],
    mounted() {
      const script = document.createElement("script")
      script.src = "https://js.hsforms.net/forms/v2.js"
      document.body.appendChild(script)
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: this.portalId,
            formId: this.formId,
            target: "#hubspotForm"
          })
        }
      })
    }
  }
</script>
