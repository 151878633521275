import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
Vue.use(Vuex)

import DealerStore from './stores/dealer_store'
import SearchStore from './stores/search_store'
import FrontEndStore from './stores/front_end_store'
import DealerSelectorStore from './stores/dealer_selector_store'
import DealerCardPickerStore from './stores/dealer_card_picker_store'

const store = new Vuex.Store({
  modules: {
    DealerStore,
    SearchStore,
    FrontEndStore,
    DealerSelectorStore,
    DealerCardPickerStore
  }
})

export default store
