const dealer = {
  methods: {
    isMatchPostcode(dealer, postcode) {
      let postcodes = dealer.supported_postcodes.length > 0 ? dealer.supported_postcodes.split(',') : []
      return dealer.postcode == postcode || postcodes.indexOf(postcode) > -1
    },
  }
}

export default dealer
