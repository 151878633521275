import Vue from 'vue/dist/vue.runtime.min'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import HomeFrontEnd from './components/home/front_end'
import HomeSearch from './components/home/search'
import FindADealer from './components/find_a_dealer/home'
import DealerDetail from './components/find_a_dealer/dealer_detail'

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/search', component: HomeSearch, props: route => ({
              q: route.query.q,
              page: route.query.page
            })
    },
    { path: '/find-a-dealer', component: FindADealer },
    { path: '/dealers/:dealerSlug', component: DealerDetail, name: 'dealer-details' },
    { path: '*', component: HomeFrontEnd }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
