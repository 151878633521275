import axios from 'axios'

const DealerSelectorStore = {
  namespaced: true,
  state: {
    dealers: [],
    mainContent: '',
    postcode: ''
  },
  mutations: {
    many(state, data) {
      state.dealers = data.dealers
      return state
    }
  },
  actions: {
    index(context, query) {
      axios
        .get('/api/dealers')
        .then(response => context.commit('many', response.data))
    }
  }
}

export default DealerSelectorStore
