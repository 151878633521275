<template>
  <select :id="id" :name="name">
    <option :value="item.id" v-for="item in results" :key="item.id">{{ item.name }}</option>
  </select>
</template>

<script>
  import EventBus from '../../event_bus'
  import dealer from '../../mixins/dealer'
  export default {
    props: ['name', 'id', 'required'],
    mixins: [dealer],
    data() {
      return this.$store.state.DealerSelectorStore
    },
    mounted() {
      EventBus.$on('postcode_change', postcode => this.postcode = postcode)
      this.$store.dispatch('DealerSelectorStore/index')
      console.log('Dealer selector: mounted')
    },
    computed: {
      results() {
        let data = this.dealers.filter(dealer => {
          if (this.postcode && this.postcode.length > 2) {
            return this.isMatchPostcode(dealer, this.postcode)
          }
          return false
        })

        if(data.length < 1) {
          if (this.postcode && this.postcode.length > 2) {
            return [{
                      name: 'Select one dealer available near you'
                    }].concat(this.dealers)
          } else {
            return [{
                      name: 'Please enter your postcode first'
                    }]
          }
        } else {
          return [{
                    name: 'Select one dealer available near you'
                  }].concat(data)
        }
      }
    },
    beforeDestroy() {
      EventBus.$off('postcode_change')
      this.postcode = ''
      console.log('Dealer selector: before destroy')
    }
  }
</script>
