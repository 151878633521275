import Vue from 'vue/dist/vue.runtime'

const footer = {
  methods: {
    runScript() {
      const self = this
      Vue.nextTick(function () {
        $('#back_to_top').click(function(){
          self.moveToTop()
        }) 
      })           
    },
    moveToTop() {
      $('html,body').animate({ scrollTop: 0 }, 'slow')
    }
  }
}

export default footer
