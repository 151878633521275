<template>
  <section class="dealer main-body">
    <div class="container">
      <div class="row block-map">
        <div class="col-md-3 col-sm-4">
          <div class="dealer-sidebar">
            <div id="cms_content" v-html="mainContent"></div>
            <div class="form-group">
              <label for="stateSelector">Region</label>
              <select class="form-control" id="stateSelector"
                      placeholder="Region"
                      v-model="state">
                <option value="">Select</option>
                <option v-for="state in states" :value="state">{{state}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="postcode">Postcode</label>
              <input class="form-control" id="postcode"
                     type="text"
                     placeholder="Postcode"
                     v-model="postcode"
              />
            </div>
            <p class="message-results" v-if="message">{{message}}</p>
          </div>
        </div>
        <div class="col-md-9 col-sm-8">
          <div class="dealer-map">
            <div id="dealers-map">
              <google-map
                ref="map"
                :center="center"
                :zoom="zoom"
                >
                <google-marker v-for="result in results" :key="result.id"
                  :position="{lat: parseFloat(result.position.lat), lng: parseFloat(result.position.lng)}"
                  :clickable="true"
                  :draggable="false"
                  :icon="marker(result.icon)"
                  @click="navigateDetail(result)"
                ></google-marker>
              </google-map>
            </div>
          </div>
        </div>
      </div>

      <div class="row dealer-results" id="dealer-list">
        <dealer-card :dealer-data="result" :detail-route-name="'dealer-details'" v-for="result in results" :key="result.id"></dealer-card>
      </div>
    </div>
  </section>
</template>

<script>
  import dealer from '../../mixins/dealer'
  import DealerCard from './dealer_card'
  import axios from 'axios'
  import {gmapApi} from 'vue2-google-maps'
  export default {
    mixins: [dealer],
    data() {
      return this.$store.state.DealerStore
    },
    computed: {
      google: gmapApi
    },
    mounted() {
      let slug = '/find-a-dealer'
      axios.get('/api' + slug).then(response => this.mainContent = response.data.content)
      this.$store.dispatch('DealerStore/index')
    },
    computed: {
      message() {
        if (!this.postcode && !this.state)
          return null
        let match = false
        let currentDealers = this.results
        if (this.state) {
          match = currentDealers.length
          return `Found ${match} match${match == 1 ? '' : 'es'}.`
        } else if (this.postcode && this.postcode.length > 2) {
          match = currentDealers.filter(dealer => {
            return dealer.postcode == this.postcode
          }).length
          let nearby = currentDealers.length - match
          let msg = `Found ${match} match${ match < 2 ? '' : 'es'}`
          if (nearby > 0) {
            msg += match == 0 ? `, but ${nearby} nearby result${nearby < 2 ? '' : 's'}` : `, and ${nearby} nearby result${nearby < 2 ? '' : 's'}`
          }
          return msg + "."
        }
      },
      results() {
        return this.dealers.filter(dealer => {
          if (this.postcode && this.postcode.length > 2) {
            return this.isMatchPostcode(dealer, this.postcode)
          } else if (this.state) {
            return this.isMatchState(dealer, this.state)
          }
          return true
        })
      }
    },
    components: {
      'dealer-card': DealerCard
    },
    watch: {
      results : function(_) {
        this.fitBounds()
      }
    },
    methods: {
      marker(src) {
        return new google.maps.MarkerImage(src, null, null, null, new google.maps.Size(130 / 4, 201 / 4))
      },
      fitBounds() {
        let currentDealers = this.results
        if (currentDealers.length == 0) {
          this.center = {lat: -25.274398, lng: 133.77513599999997}
          this.zoom = 5
          return
        }
        let bounds = new google.maps.LatLngBounds()
        for (let result of currentDealers) {
          bounds.extend({
            lat: parseFloat(result.position.lat),
            lng: parseFloat(result.position.lng)
          })
        }

        if (currentDealers.length < 3) {
          let offset = 0.01
          let center = bounds.getCenter()
          bounds.extend(new google.maps.LatLng(center.lat() + offset, center.lng() + offset))
          bounds.extend(new google.maps.LatLng(center.lat() - offset, center.lng() - offset))
        }
        this.$refs.map.fitBounds(bounds)
      },
      navigateDetail(dealer) {
        this.$router.push({name: 'dealer-details', params: { dealerSlug: dealer.slug, dealer: dealer }})
      },
      isMatchState(dealer, state) {
        return state.toLowerCase() === dealer.state.toLowerCase() || state.toLowerCase() === dealer.country.toLowerCase()
      }
    }
  }
</script>
