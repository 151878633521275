<template>
  <div id="app" class="site-inner">
    <site-header></site-header>
    <div class="content">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
  import SiteHeader from './components/shared/site_header.vue'
  import SiteFooter from './components/shared/site_footer.vue'

  export default {
    data() {
      return {}
    },
    mounted() {
    },
    components: {
      SiteHeader,
      SiteFooter
    }
  }
</script>
