const header = {
  mounted() {
    $('#mainmenu-search', this.$el).on('shown.bs.modal', function(e) {
      $('.search-field', this).focus()
    })
    $('#mainmenu-search', this.$el).on('hidden.bs.modal', function(e) {
      $('.search-field', this).val('')
    })
  },
  methods: {
    searchFormSubmit(e) {
      let q = $('.search-field', this.$el).val()
      if(q != '' && q != undefined) {
        $('#mainmenu-search', this.$el).modal('hide')
        this.$router.push(`/search?q=${q}`)
      }
    }
  }
}

export default header
