<template>
    <section class="main-body">
      <div id="dealer-info" class="container detail-dealer">
          <h1 class="dealer-name" ref="dealerName"></h1>
          <div class="row">
              <div class="col-sm-12">
                  <div class="dealer-infor row" ref="dealerInfor">
                      <div class="col-sm-6 col-md-3">
                          <div class="address">
                              <h3>Address</h3>
                              <p class="dealer-address" ref="address"></p>
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-3">
                          <div class="phone">
                              <h3>Phone</h3>
                              <p class="dealer-phone" ref="dealerPhone"></p>
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-3">
                          <div class="email">
                              <h3>Email</h3>
                              <p class="dealer-email"></p>
                          </div>
                      </div>
                      <div class="col-sm-6 col-md-3">
                          <div class="website">
                              <p class="dealer-website"></p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row block-map">
              <div :class="thumbnailBg ? 'col-sm-6': 'col-sm-12' ">
                  <div class="dealer-map">
                      <div id="dealer-map">
                          <google-map ref="mapRef" :center="mapCenter" :zoom="13">
                              <google-marker ref="makerRef" :position="mapCenter" :clickable="false" :draggable="false" :icon="markerIcon"></google-marker>
                          </google-map>
                      </div>
                  </div>
              </div>
              <div class="col-sm-6" if="thumbnailBg">
                  <div class="dealer-thumbnail">
                      <div class="dealer-thumbnail-bg" ref="dealerThumbnailBg"></div>
                </div>
            </div>
          </div>
          <div class="row">
              <div class="col-sm-12" ref="dealerTbl"></div>
          </div>
          <div class="contact-dealer">
              <h2 class="text-center mb-40 h1">Contact us Today!</h2>
          </div>
      </div>
  </section>
</template>

<script>
  import axios from 'axios'
  import Vue from 'vue/dist/vue.runtime'
  import afterBindScript from '../../mixins/after_bind_script'

  export default {
    mixins: [afterBindScript],
    data() {
      return {
        mainContent: '',
        findAFealerItem: null,
        mapCenter: {
          lat: 0,
          lng: 0
        },
        markerIcon: '',
        thumbnailBg: ''
      }
    },
    mounted() {
      let slug = this.$route.params['dealerSlug']
      axios.get('/api/dealer-details/' + slug).then(response => {
        this.mainContent = response.data.content
        this.bindData()
      })
      this.findAFealerItem = $("#navbar-main a[href='/find-a-dealer']")
      this.highlightMenuItem()
      this.runScript()
    },
    methods: {
      highlightMenuItem() {
        this.findAFealerItem.addClass('menu-item-active')
      },
      bindData() {
        this.mapCenter.lat = parseFloat($(this.mainContent).find('google-map').attr('lat'))
        this.mapCenter.lng = parseFloat($(this.mainContent).find('google-map').attr('lng'))
        this.markerIcon = $(this.mainContent).find('google-marker').attr('icon')
        $(this.$refs.dealerName).html($(this.mainContent).find('h1.dealer-name').html())
        $(this.$refs.dealerInfor).html($(this.mainContent).find('.dealer-infor').html())
        $(this.$refs.dealerTbl).html($(this.mainContent).find('.dealer-tbl').html())
        this.thumbnailBg = $(this.mainContent).find('.dealer-thumbnail-bg').html()
        if(this.thumbnailBg) {
          $(this.$refs.dealerThumbnailBg).html($(this.mainContent).find('.dealer-thumbnail-bg').html())
        }
      }
    },
    beforeDestroy() {
      this.findAFealerItem.removeClass('menu-item-active')
    }
  }
</script>
