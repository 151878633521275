<template>
  <div class="col-md-3 col-sm-4 col-xs-6">
    <div class="item-dealer">
      <div class="cp-info">
        <router-link :to="{name: routeName, params: { dealerSlug: dealer.slug, dealer: dealer }}">
          <h3 class="name">{{ dealer.name }}</h3>
          <p class="address">{{ dealer.address }} <br/>{{ dealer.suburb }} </p>
          <p class="postcode">{{ dealer.state }} {{ dealer.postcode }}</p>
        </router-link>
        <p class="phone"><a :href="realPhone">{{ dealer.phone }}</a></p>
      </div>
      <div class="cp-wpsl-services" v-if="displayServices">
        <span class="tick" v-for="service in services"><i class="fa fa-check"></i>&nbsp;{{ service }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue/dist/vue.runtime'
  import afterBindScript from '../../mixins/after_bind_script'

  export default {
    mixins: [afterBindScript],
    props: {
      dealerData: {
        required: true,
        type: Object
      },
      detailRouteName: {
        required: true,
        type: String,
        default: 'dealer-details'
      }
    },
    data() {
      return {
        dealer: this.$props.dealerData,
        routeName: this.$props.detailRouteName
      }
    },
    computed: {
      realPhone() {
        return 'tel:' + this.dealer.real_phone
      },
      displayServices() {
        return this.services.length > 0
      },
      services() {
        return this.dealer.services ? this.dealer.services.split(',') : []
      }
    }
  }
</script>
