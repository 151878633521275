import EventBus from '../event_bus'
import Vue from 'vue/dist/vue.esm'
import axios from 'axios'

Vue.mixin({
  methods: {
    onSubmit(event) {
      let form = $(event.currentTarget)
      let formData = new FormData(form[0])
      this.resetForm(form)
      formData.append('format', 'json')
      axios.post(form.attr('action'), formData)
        .then(response => {
          if(response.status == 200) {
            if(response.data.error) {
              response.data.error.forEach(item => {
                let cid = item.split(':')[0]
                let message = item.split(':')[1]
                $('#'+ cid).closest('.form-group')
                  .addClass('error')
                  .append(`<div class='error-message'>${message}</div>`)
              })
              form.prepend('<div class="error-message">There was a problem with your submission. Errors have been highlighted below.</div>')
              form.animate({ }, 'slow')
              $('html,body').animate({ scrollTop: form.offset().top }, 'slow');
            } else {
              if($('input.success-url').length > 0) {
                window.location.href = $('input.success-url').val()
              } else {
                form.replaceWith(response.data.notice)
              }
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    resetForm(form) {
      $('.error-message', form).remove()
      $('.form-group', form).removeClass('error')
    },
    onBlurPostcode(event) {
      if($('.dealer-selector', this.$el).length > 0) {
        EventBus.$emit('postcode_change', $(event.currentTarget).val())
      }
    },
    onChangeInput(event, toggleElement) {
      if ($(event.currentTarget).is(':checked')) {
        $('.' + toggleElement).show()
      } else {
        $('.' + toggleElement).hide()
      }
    }
  }
})
