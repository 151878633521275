require('bootstrap')
require('slick-carousel')

window.jQuery = require('jquery')

require('select2')
require("owl.carousel")
require('../src/flexslider/jquery.flexslider')
require('jquery-zoom')

import 'core-js/stable'
import Vue from 'vue/dist/vue.runtime'
import store from '../src/vuex'
import router from '../src/routes'
import header from '../src/mixins/header'
import footer from '../src/mixins/footer'
import '../src/filters/strings'
import '../src/mixins/generic_form'

import Vuex from 'vuex'
Vue.use(Vuex)
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import VueRecaptcha from 'vue-recaptcha'
Vue.component('VueRecaptcha', VueRecaptcha)
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)
import DealerSelector from '../src/components/shared/dealer_selector'
Vue.component('DealerSelector', DealerSelector)
import DealerCardPicker from '../src/components/shared/dealer_card_picker'
Vue.component('DealerCardPicker', DealerCardPicker)
import HubspotsForm from '../src/components/shared/hubspots_form'
Vue.component('HubspotsForm', HubspotsForm)
import VueDataTable from '../src/components/shared/vue_data_table'
Vue.component('VueDataTable', VueDataTable)
import VuePapaParse from 'vue-papa-parse'
Vue.use(VuePapaParse)

import * as VueGoogleMap from 'vue2-google-maps'

import App from '../src/app.vue'

Vue.use(VueGoogleMap, {
  load: {
    key: 'AIzaSyAmKHg9Q_j7RoYXkp8NJXBdRULizl5g2Wc'
  },
})
Vue.component('google-map', VueGoogleMap.Map)
Vue.component('google-marker', VueGoogleMap.Marker)

const app = new Vue({
  router,
  store,
  mixins: [header, footer],
  render() {
    return (
      <App />
    )
  }
}).$mount('#app')
