import axios from 'axios'

const SearchStore = {
  namespaced: true,
  state: {
    currentPage: 1,
    perPage: 10,
    mainContent: ''
  }
}

export default SearchStore
