<template>
  <div v-once>
    <datatable :exactSearch="true" :title="tableTitle" :printable="false" :columns="columns" :rows="rows"></datatable>
  </div>
</template>

<script>
  import axios from 'axios'
  import DataTable from './data_table.vue'
  export default {
    props: ['csvUrl', 'tableTitle'],
    data() {
      return {
        csvHeader: [],
        csvRows: [],
        columns: [],
        rows: []
      }
    },
    mounted() {
      const self = this;
      this.$papa.parse(this.csvUrl, {
        download: true,
        complete: function(results, file) {
          self.csvHeader = results.data[0]
          results.data.shift()
          self.csvRows = results.data
          self.reloadDataTable()
        }
      })
    },
    methods: {
      reloadDataTable() {
        this.csvHeader.forEach((item, idx) => {
            this.columns.push({
              label: item,
              field: 'idx' + idx,
              numeric: false,
              html: false
            })
        })
        const lengthData = this.csvHeader.length

        this.csvRows.forEach(item => {
          if(item) {
            let rowObj = {}
            for(let i=0; i < lengthData; i++) {
              rowObj['idx'+i] = item[i]
            }
            this.rows.push(rowObj)
          }
        })
      }
    },
    components: {
      "datatable": DataTable
    }
  }
</script>
